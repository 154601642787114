
	frappe.templates['comms_log'] = `<div class="call-detail-wrapper">
    <div class="head flex justify-between">
        <div>
            <span class="bold">WhatsApp</span>
            <span class="text-muted"> • {{ comment_when(created_on) }}</span>
            {% if (by_who) %}
                <span class="text-muted"> • {{ by_who }}</span>
            {% endif %}
        </div>
        <span class="indicator-pill {{ indicator_color }}">
            <span class="hidden-xs small" style="color: {{ transmission_status_font_color }}">{{ transmission_status }}</span>
        </span>
    </div>

    <div class="body pt-3">
        <span> Recipient {{ mobile_number }} • {{ purpose }}</span>
        {% if (error_message) { %}
            <div class="summary pt-3">
                <i class="text-muted">{{ error_message }}</i>
            </div>
        {% } %}
    </div>
</div>
`;
