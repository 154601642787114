frappe.provide("frappe.ui.misc");
frappe.ui.misc.about = function () {
	if (!frappe.ui.misc.about_dialog) {
		var d = new frappe.ui.Dialog({ title: "oro247 - Your Business OS" });

		$(d.body).html(
			repl(
				"<div>\
		<p><i class='fa fa-globe fa-fw'></i>\
			Website: <a href='https://www.oro247.com' target='_blank'>https://www.oro247.com</a></p>\
		<hr>\
		<h4>Installed Apps</h4>\
		<div id='about-app-versions'>Loading versions...</div>\
		<hr>\
		<p class='text-muted'>&copy; OPX Technology Pte Ltd | oro247.com </p> \
		</div>",
				frappe.app
			)
		);

		frappe.ui.misc.about_dialog = d;

		frappe.ui.misc.about_dialog.on_page_show = function () {
			if (!frappe.versions) {
				frappe.call({
					method: "frappe.utils.change_log.get_versions",
					callback: function (r) {
						show_versions(r.message);
					},
				});
			} else {
				show_versions(frappe.versions);
			}
		};

		var show_versions = function (versions) {
			var $wrap = $("#about-app-versions").empty();
			$.each(Object.keys(versions).sort(), function (i, key) {
				var v = versions[key];
				var title = v.title;
                if (title === "oro247Architectural") {
				    title = "oro247Build";
                } else if (title === "ERPNext") {
				    title = "Core";
				} else if (title === "Frappe Framework") {
				    title = "Base";
				} else if (title.startsWith("Frappe")) {
                    title = title.slice("Frappe".length);
                    title = title.trimStart();
                }
				if (v.branch) {
					var text = $.format("<p><b>{0}:</b> v{1} ({2})<br></p>", [
						title,
						v.branch_version || v.version,
						v.branch,
					]);
				} else {
					var text = $.format("<p><b>{0}:</b> v{1}<br></p>", [title, v.version]);
				}
				$(text).appendTo($wrap);
			});

			frappe.versions = versions;
		};
	}

	frappe.ui.misc.about_dialog.show();
};
