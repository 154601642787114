frappe.ui.form.on('Payment Entry', {
	onload: function(frm) {
		frm.set_query("paid_from", function() {
			frm.events.validate_company(frm);

            var party_account_types = [frappe.boot.party_account_types[frm.doc.party_type]]
            party_account_types.push("Income Account");
			var account_types = in_list(["Pay", "Internal Transfer"], frm.doc.payment_type) ?
				["Bank", "Cash"] : party_account_types;
			return {
				filters: {
					"account_type": ["in", account_types],
					"is_group": 0,
					"company": frm.doc.company
				}
			}
		});

		frm.set_query("paid_to", function() {
			frm.events.validate_company(frm);

			var party_account_types = [frappe.boot.party_account_types[frm.doc.party_type]];
			party_account_types.push("Expense Account");
			var account_types = in_list(["Receive", "Internal Transfer"], frm.doc.payment_type) ?
				["Bank", "Cash"] : party_account_types;
			return {
				filters: {
					"account_type": ["in", account_types],
					"is_group": 0,
					"company": frm.doc.company
				}
			}
		});

        // force un-hide sections
		frm.fields_dict["references"].section.df.depends_on = "";
		frm.fields_dict["remarks"].section.df.depends_on = "";
	},

	refresh: function(frm) {
        // add WA button
        if (frm.doc.docstatus == 1) {
            // Add buttons - General
            frm.add_custom_button(
                __('<i class="fa fa-whatsapp"></i> WhatsApp'),
                function () {
                    frappe.confirm(__("WhatsApp <span class='oro247app-oro-underline'>Receipt</span> to {0}?", [frm.doc.party]), () => {
                        whatsapp_payment_entry(frm);
                    });
                }
            ).css({"color":"white", "background-color": "#25D366"});
        }
    },
});


function whatsapp_payment_entry(frm) {
    frappe.call({
        method: "oro247app.remote_api.whatsapp_business.whatsapp_payment_entry",
        freeze: true,
        freeze_message: __("Sending WhatsApp message..."),
        args: {
            doc: frm.doc,
        },
        callback: function (r) {
            if (!r.exc) {
                 if (r.message && r.message.url) {
                    window.open(r.message.url, '_blank').focus();
                    return;
                } else if (r.message && r.message.reason) {
                    frappe.msgprint({
                        message: r.message.reason,
                        alert: true,
                        indicator: "orange",
                    });
                    return;
                } else if (r.message && r.message.success == true) {
                    frappe.msgprint({
                        message: r.message.message,
                        alert: true,
                        indicator: "blue",
                    });
                    return;
                }
            }
            frappe.msgprint({
                message: __("Unable to WhatsApp"),
                alert: true,
                indicator: "red",
            });
        },
        error: function(r) {
            if (r.responseJSON && r.responseJSON.type == "THROTTLED" && r.responseJSON.timeLeft != undefined) {
                frappe.msgprint({
                    message: `Hold it cowboy, please slow down. Try again ${r.responseJSON.timeLeft} later.`,
                    alert: true,
                    indicator: "red",
                });
                return;
            } else if (r.responseJSON && r.responseJSON.error) {
                frappe.msgprint({
                    message: r.responseJSON.error,
                    alert: true,
                    indicator: "red",
                });
                return;
            }
            frappe.msgprint({
                message: __("Unable to WhatsApp"),
                alert: true,
                indicator: "red",
            });
        },
    });
}