
	frappe.templates['activity_log'] = `<div class="call-detail-wrapper">
    <div class="head flex justify-between">
        <div>
            <span class="bold">{{ activity_type }}</span>
            <span class="text-muted"> • {{ comment_when(created_on) }}</span>
            {% if (by_who) %}
                <span class="text-muted"> • {{ by_who }}</span>
            {% endif %}
        </div>
    </div>

    <div class="body pt-3">
        <span> {{ activity_log }}</span>
    </div>
</div>
`;
