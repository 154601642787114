
	frappe.templates['form_info_item'] = `<div class="oro247app-item">
    <div class="oro247app-label">
        {% if (label != null) %}
            {{ label }}
        {% } %}
    </div>
    <div class="oro247app-data">
        {% if (data_html != null) %}
            {{ data_html }}
        {% } %}
    </div>
</div>
`;
